import { Box, BoxProps, Typography, TypographyProps } from '@material-ui/core'
import React from 'react'
import Interweave from 'interweave'
import { Box as CBox } from '@chakra-ui/react'

export interface TextProps extends TypographyProps {
    box?: BoxProps
    html?: boolean
    label: string
    component?: any
}

// https://www.w3.org/TR/CSS2/sample.html
const defaultHtmlCss = {
    li: { display: "list-item" },
    head: { display: "none" },
    table: { display: "table", borderSpacing: "2px" },
    tr: { display: "table-row" },
    thead: { display: "table-header-group" },
    tbody: { display: "table-row-group" },
    tfoot: { display: "table-footer-group" },
    col: { display: "table-column" },
    colgroup: { display: "table-column-group" },
    'td, th': { display: "table-cell" },
    caption: { display: "table-caption", textAlign: "center" },
    th: { fontWeight: "bolder", textAlign: "center" },
    body: { margin: "8px" },
    h1: { fontSize: "2em", margin: ".67em 0" },
    h2: { fontSize: "1.5em", margin: ".75em 0" },
    h3: { fontSize: "1.17em", margin: ".83em 0" },
    'h4, p, blockquote, ul, fieldset, form, ol, dl, dir, menu': { margin: "1.12em 0" },
    h5: { fontSize: ".83em", margin: "1.5em 0" },
    h6: { fontSize: ".75em", margin: "1.67em 0" },
    "h1, h2, h3, h4, h5, h6, b, strong": { fontWeight: "bolder" },
    blockquote: { marginLeft: "40px", marginRight: "40px" },
    "i, cite, em, var, address": { fontStyle: "italic" },
    "pre, tt, code, kbd, samp": { fontFamily: "monospace" },
    pre: { whiteSpace: "pre" },
    "button, textarea, input, select": { display: "inline-block" },
    big: { fontSize: "1.17em" },
    "small, sub, sup": { fontSize: ".83em" },
    sub: { verticalAlign: "sub" },
    sup: { verticalAlign: "super" },
    "thead, tbody, tfoot": { verticalAlign: "middle" },
    "td, th, tr": { verticalAlign: "inherit" },
    "s, strike, del": { textDecoration: "line-through" },
    hr: { border: "1px inset" },
    "ol, ul, dir, menu, dd": { marginLeft: "40px" },
    ol: { listStyleType: "decimal" },
    "ol ul, ul ol, ul ul, ol ol": { marginTop: "0", marginBottom: "0" },
    "u, ins": { textDecoration: "underline" },
    "br:before": { content: '"\\A"', whiteSpace: "pre-line" },
    center: { textAlign: "center" },
    ":link, :visited": { textDecoration: "underline" },
    ":focus": { outline: "thin dotted invert" },
    a: { color: '#5261AC', fontWeight: 'bolder' } // specific to ilgroup
}

export const Text: React.FC<TextProps> = ({ box, html, label, component, ...props }) => {
    const tag = component
    return (
        <Box {...box}>
            <Typography {...{ ...props, component: html ? 'div' : tag }}>
                {html ? <CBox sx={defaultHtmlCss}><Interweave content={label} /></CBox> : label}
            </Typography>
        </Box>
    )
}
