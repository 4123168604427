import { Button, Grid } from '@material-ui/core'
import { map } from 'lodash'
import React, { CSSProperties } from 'react'

export const timeSlots = {
    1: '8am - 12pm',
    2: '12pm - 5pm',
    3: '5pm - 8pm'
}

const styles: { [index: string]: CSSProperties } = {
    default: {
        border: '2px solid rgba(58, 49, 96, 0.15)',
        color: '#615A80',
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.04em',
        lineHeight: 1,
        height: 50,
        textTransform: 'unset'
    },
    selected: {
        background: '#EBEDF7',
        border: '2px solid #EBEDF7',
        color: '#4D599E'
    }
}

export interface ContactLeftFieldsButtonsProps {
    error?: string
    setTimeSlot: (number: number) => void
    value: number
}

export const ContactLeftFieldsButtons: React.FC<ContactLeftFieldsButtonsProps> = ({
    error,
    setTimeSlot,
    value
}) => {
    return (
        <>
            <Grid container spacing={4}>
                {map(timeSlots, (timeSlot, key) => (
                    <Grid key={key} item xs={12} md={4}>
                        <Button
                            fullWidth
                            style={{
                                ...styles.default,
                                ...(value === Number(key)
                                    ? styles.selected
                                    : {})
                            }}
                            onClick={() => setTimeSlot(Number(key))}
                        >
                            {timeSlot}
                        </Button>
                    </Grid>
                ))}
            </Grid>
            {/* {error && <FormHelperText error>{error}</FormHelperText>} */}
        </>
    )
}
