import { Box } from '@material-ui/core'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import {
    ContactRightDetails,
    ContactRightDetailsProps
} from './ContactRightDetails'
import {
    ContactRightHeading,
    ContactRightHeadingProps
} from './ContactRightHeading'

export interface ContactRightProps
    extends ContactRightHeadingProps,
        ContactRightDetailsProps {}

export const ContactRight: React.FC<ContactRightProps> = ({
    featureList,
    title,
    description
}) => {
    const desktop = useDesktop()
    return (
        <Box
            px={desktop ? 15 : 7.8}
            mb={desktop ? 0 : 11}
            mx={desktop ? [0, 0, -7.8] : -2.5}
        >
            <ContactRightHeading title={title} description={description} />
            <ContactRightDetails featureList={featureList} />
        </Box>
    )
}
