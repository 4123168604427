import { MetadataProps, OtherMeta } from 'common/components/atoms/Metadata'
import { getWebchatData } from 'common/components/molecules/WebchatButton'
import { LayoutProps } from 'common/components/organisms/Layout'
import { getCustomNavigationMenuData } from 'common/utils/getCustomNavigationMenuData'

export const getLayoutData = (data: any): LayoutProps => {
    const jumbotronVariant =
        data.hero?.[0].jumbotron__variant ||
        data.jumbotron__variant ||
        data.campaign_feature_variant

    return {
        ...data.layout,
        customNavigationMenu: getCustomNavigationMenuData(
            data.custom_navigation_menu_567451f__navigation_menu
        ),
        noBreadcrumbs: data.no_breadcrumbs === 'Yes',
        banner: {
            variant: data.call_to_action__variant,
            title: data.call_to_action__title,
            callbackLabel: data.call_to_action__label,
            clickURL: data.call_to_action__url,
            clickID: data.call_to_action__analytics_id,
            description: '',
        },
        metadata: {
            title: data.metadata__meta_title,
            description: data.metadata__meta_description,
            ogTitle: data.metadata__og_title,
            ogDescription: data.metadata__og_description,
            ogImage: data.metadata__og_image,
            otherMeta: (data.metadata__other_meta || []).map(
                (meta: OtherMeta) => ({
                    name: meta.name,
                    content: meta.content,
                })
            ),
            twitterSite: data.metadata__twitter_site,
            twitterCreator: data.metadata__twitter_creator,
            twitterTitle: data.metadata__twitter_title,
            twitterDescription: data.metadata__twitter_description,
            twitterImage: data.metadata__twitter_image,
        } as MetadataProps,
        breadcrumbs: {
            ...data.layout.breadcrumbs,
            jumbotronVariant,
        },
        jumbotronVariant,
        webchat: getWebchatData(data),
    }
}
