import React from 'react'
import { Helmet } from 'react-helmet'

export interface OtherMeta {
    name: string
    content: string
}
export interface MetadataProps {
    lang?: string
    title: string
    description: string
    ogTitle: string
    ogDescription: string
    ogImage: string
    otherMeta: OtherMeta[]
    twitterSite: string
    twitterCreator: string
    twitterTitle: string
    twitterDescription: string
    twitterImage: string
}

export const Metadata: React.FC<MetadataProps> = ({
    lang = 'en-IE',
    title,
    description,
    ogTitle,
    ogDescription,
    ogImage,
    otherMeta = [],
    twitterSite,
    twitterCreator,
    twitterTitle,
    twitterDescription,
    twitterImage
}) => {
    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            meta={[
                {
                    name: `description`,
                    content: description
                },
                {
                    property: `og:title`,
                    content: ogTitle || title
                },
                {
                    property: `og:description`,
                    content: ogDescription || description
                },
                {
                    property: `og:image`,
                    content: ogImage
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    name: `twitter:card`,
                    content: `summary`
                },
                {
                    name: `twitter:site`,
                    content: twitterSite
                },
                {
                    name: `twitter:creator`,
                    content: twitterCreator
                },
                {
                    name: `twitter:title`,
                    content: twitterTitle || title
                },
                {
                    name: `twitter:description`,
                    content: twitterDescription || description
                },
                {
                    name: `twitter:image`,
                    content: twitterImage
                }
            ].concat(otherMeta)}
        />
    )
}
