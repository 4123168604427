import { Box, InputAdornment } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import {
    JumbotronCallToAction,
    JumbotronCallToActionProps,
} from 'common/components/organisms/Jumbotron/JumbotronCallToAction'
import {
    AttributionLeadData,
    submitAttributionLead,
} from 'common/hooks/useAttributionLead'
import { useDesktop } from 'common/hooks/useDesktop'
import { createGenesysCallback } from 'common/hooks/useGenesysApi'
import { getToken } from 'common/hooks/useOauthTokenEndpoint'
import { createAnonymousTicket } from 'common/hooks/useZendeskData'
import { GENESYS_FLAG } from 'core/config/env'
import { TextField } from 'mui-rff'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import TagManager from 'react-gtm-module'
import NumberFormat from 'react-number-format'
import MaskedInput from 'react-text-mask'
import { ContactLeftFieldsButtons } from './ContactLeftFieldsButtons'
import { ContactLeftFieldsSelect } from './ContactLeftFieldsSelect'
import { CountryCodeAdornment } from './CountryCodeAdornment'

const genesysFlag = !GENESYS_FLAG

const validate = (values: any) => {
    const errors: {
        [index: string]: string
    } = {}

    if (!values.fullName) errors.fullName = 'Please provide a name'
    if (!values.phoneNumber)
        errors.phoneNumber = 'Please provide a phone number'
    if (!values.timeSlot) errors.timeSlot = 'Please pick a time slot'

    return errors
}

export interface ZenDeskAnonymousTicket {
    requesterName: string
    subject: string
    description: string
    tags: string[]
    type: string
    phoneNumber: number
    timeToCall: string
    commentText: string
}

export interface ContactFormValues {
    tabName: string
    heading: string
    titleForm: string
    descriptionForm: string
    firstLabelForm: string
    secondLabelForm: string
    footerDescriptionForm: string
    hideForm?: boolean
    noteDescriptionForm: string
    pickTimeSlotLabelForm: string
}

interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void
}

const nameMask = (rawValue: string): RegExp[] => {
    const mask = /[A-Za-z' ]+/
    const strLength = String(rawValue).length
    const nameMask: RegExp[] = []

    for (let i = 0; i <= strLength; i++) {
        nameMask.push(mask)
    }

    return nameMask
}

function TextMaskCustom(props: TextMaskCustomProps) {
    const { inputRef, ...other } = props

    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null)
            }}
            showMask={false}
            guide={false}
            mask={nameMask}
        />
    )
}

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat<number> | null) => void
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                })
            }}
            format='### ### ####'
            isNumericString
            prefix=''
        />
    )
}

export interface ContactLeftFieldsProps {
    callToAction: JumbotronCallToActionProps
    formValues: ContactFormValues
    toggleConfirmation?: () => void
}

export const ContactLeftFields: React.FC<ContactLeftFieldsProps> = ({
    callToAction,
    formValues,
    toggleConfirmation,
}) => {
    const [countryCode, setCountryCode] = useState('353')
    const setCountryCodeForIntegration = (_countryCode: string) => {
        setCountryCode(_countryCode)
    }
    const desktop = useDesktop()

    return (
        <Form
            mutators={{
                setTimeSlot: (args, state, utils) => {
                    utils.changeValue(state, 'timeSlot', () => args[0])
                },
            }}
            initialValues={genesysFlag ? null : { timeSlot: 0 }}
            onSubmit={async (values) => {
                // pushing a pageview in GA
                const queryString = window.location.search
                let calcReference = queryString.substring(3, queryString.length)
                let virtualURL = ''

                switch (calcReference) {
                    case 'one-plan':
                        virtualURL = '/talk-to-us/callback-requested/one-plan'
                        break
                    case 'one-plan-results':
                        virtualURL =
                            '/talk-to-us/callback-requested/one-plan-quote'
                        break
                    case 'income-protection':
                        virtualURL =
                            '/talk-to-us/callback-requested/income-protection'
                        break
                    case 'MAPS-quiz':
                        virtualURL = '/talk-to-us/callback-requested/MAPS-quiz'
                        break
                    case 'life-long':
                        virtualURL = '/talk-to-us/callback-requested/life-long'
                        break
                    case 'term-life':
                        virtualURL = '/talk-to-us/callback-requested/term-life'
                        break
                    case 'mortgage-protection':
                        virtualURL =
                            '/talk-to-us/callback-requested/mortgage-protection'
                        break
                    case 'pensions':
                        virtualURL = '/talk-to-us/callback-requested/pensions'
                        break
                    default:
                        break
                }

                if (virtualURL !== '') {
                    try {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'pageview',
                                pageTitle: window.location.pathname,
                                virtualURL: virtualURL,
                            },
                        })
                    } catch (err) {}
                }

                if (genesysFlag) {
                    // ZenDesk integration
                    const timeToCallIndex =
                        values.timeSlot === 1
                            ? 'before_12pm'
                            : values.timeSlot === 2
                            ? '12pm_-_5pm_'
                            : 'after_5pm'
                    const timeToCallText =
                        values.timeSlot === 1
                            ? '8am - 12 Timeslot'
                            : values.timeSlot === 2
                            ? '12 - 5pm Timeslot'
                            : '5pm - 8pm Timeslot'
                    let phoneNumberWithPrefix =
                        countryCode + values.phoneNumber.toString()
                    let ticket = {
                        requesterName: values.fullName,
                        type: 'incident',
                        subject: `${timeToCallText} - Requested call from ${values.fullName} for ${formValues.tabName}`,
                        tags: ['contact-us'],
                        commentText: `${timeToCallText} - Requested call from ${values.fullName} to number: +${countryCode} ${values.phoneNumber} for ${formValues.tabName}`,
                        phoneNumber: Number(phoneNumberWithPrefix),
                        timeToCall: timeToCallIndex,
                    } as ZenDeskAnonymousTicket

                    createAnonymousTicket(ticket)
                }

                if (!genesysFlag) {
                    const token = await getToken()
                    await createGenesysCallback(token, values)
                }

                let leadData = {
                    fullName: values.fullName,
                    phoneNumber: Number(
                        genesysFlag
                            ? values.phoneNumberWithPrefix
                            : values.phoneNumber
                    ),
                    savedFrom: window.location.pathname,
                } as AttributionLeadData

                submitAttributionLead(leadData)
                // Display confirmation message
                toggleConfirmation?.()
            }}
            validate={validate}
            render={({ handleSubmit, form, values, errors }) => (
                <>
                    <Box mb={6}>
                        <Text
                            box={{ mb: 2.5 }}
                            label={formValues.firstLabelForm}
                        />
                        <TextField
                            // label={formValues.firstLabelForm}
                            name='fullName'
                            fullWidth
                            variant='outlined'
                            InputProps={{
                                inputComponent: TextMaskCustom as any,
                            }}
                        />
                    </Box>
                    <Box mb={6}>
                        <Text
                            box={{ mb: 2.5 }}
                            label={formValues.secondLabelForm}
                        />
                        <TextField
                            name='phoneNumber'
                            // label={formValues.secondLabelForm}
                            placeholder={formValues.secondLabelForm}
                            variant='outlined'
                            fullWidth
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                                startAdornment: (
                                    <InputAdornment
                                        position='start'
                                        style={{
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                        }}
                                    >
                                        <CountryCodeAdornment
                                            setCountryCode={
                                                setCountryCodeForIntegration
                                            }
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Text label={formValues.pickTimeSlotLabelForm} />
                    <Box mt={2.5} mb={7}>
                        {genesysFlag && (
                            <ContactLeftFieldsButtons
                                error={errors.timeSlot}
                                setTimeSlot={form.mutators.setTimeSlot}
                                value={values.timeSlot}
                            />
                        )}
                        {!genesysFlag && (
                            <ContactLeftFieldsSelect
                                error={errors.timeSlot}
                                setTimeSlot={form.mutators.setTimeSlot}
                                value={values.timeSlot}
                            />
                        )}
                    </Box>
                    <Text
                        box={{ mb: 7 }}
                        color='textSecondary'
                        html
                        label={formValues.noteDescriptionForm}
                        variant='body2'
                    />
                    <JumbotronCallToAction
                        {...callToAction}
                        onClick={handleSubmit}
                        noSpacer
                    />
                    <Text
                        box={{ mt: 7, pb: desktop ? 10.7 : 0 }}
                        color='textSecondary'
                        html
                        label={formValues.footerDescriptionForm}
                        variant='body2'
                    />
                </>
            )}
        />
    )
}
