import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import React from 'react'
import { BasicButtonProps } from './BasicButton'

export const GhostButton: React.FC<BasicButtonProps> = ({
    label,
    size = 'large',
    noSpacer,
    analyticsId,
    endIcon,
    startIcon,
    ...props
}) => {
    return (
        <Button
            {...props}
            sx={{
                '&:hover': {
                    transition: 'all 0.2s ease-in-out',
                    boxShadow: '0px 0px 20px rgba(82, 97, 172, 0.24)'
                }
            }}
            variant='ghost'
            size='lg'
            rightIcon={
                <img
                    alt='Arrow right'
                    src={endIcon}
                    style={{
                        filter:
                            'invert(43%) sepia(91%) saturate(2132%) hue-rotate(216deg) brightness(88%) contrast(92%)'
                    }}
                />
            }
        >
            {label}
        </Button>
    )
}
