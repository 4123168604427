import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { navigate } from '@reach/router'
import React from 'react'
import TagManager from 'react-gtm-module'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetAdviceButtonProps {}

export const GetAdviceButton: React.FC<GetAdviceButtonProps> = () => {
    return (
        <Button
            onClick={() => {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'navigationClick',
                        clickText: 'Get Advice',
                        clickURL: '',
                        clickID: 'Get Advice'
                    }
                })
                navigate('/get-advice')
            }}
            size='sm'
            sx={{
                mr: 6,
                filter: 'drop-shadow(0px 0px 20px rgba(82, 97, 172, 0.24))'
            }}
        >
            Get advice
        </Button>
    )
}
