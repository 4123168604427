import TagManager from 'react-gtm-module'

export interface AnalyticsEvent {
    name: string
    properties?: { [key: string]: string }
}

export const fireAnalyticsEvent = (event: AnalyticsEvent) => {
    TagManager.dataLayer({
        dataLayer: {
            event: event.name,
            ...event.properties
        }
    })
}
