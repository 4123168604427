import { Box, Grid } from '@material-ui/core'
import { CallToActionProps } from 'common/components/molecules/CallToAction'
import { Icon } from 'common/components/molecules/Icon'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { FeaturedContent } from './FeaturedContent'

export interface FeaturedContentIndentedProps {
    callToAction?: CallToActionProps
    title: string
    text?: string
    icon?: React.ReactElement
}

export const FeaturedContentIndented: React.FC<FeaturedContentIndentedProps> = ({
    icon,
    ...props
}) => {
    const desktop = useDesktop()

    return (
        <Grid container spacing={6}>
            <Grid item xs={12} lg={2}>
                {/* <Box display='flex'> */}
                {icon && (
                    <Box mt={desktop ? 1.5 : 0.5} mb={desktop ? 0 : 4}>
                        <Icon icon={icon} />
                    </Box>
                )}
            </Grid>
            <Grid item xs={12} lg={10}>
                <FeaturedContent {...props} />
            </Grid>
        </Grid>
    )
}
