import { ButtonBase, InputBase, makeStyles, Popper } from '@material-ui/core'
import Autocomplete, {
    AutocompleteCloseReason
} from '@material-ui/lab/Autocomplete'
import { Text } from 'common/components/molecules/Text'
import React from 'react'
import countryData from './countries.json'

interface CountryType {
    name: string
    abbr: string
    code: string
}

const useStyles = makeStyles({
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 5,
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18
        }
    },
    inputBase: {
        padding: 10,
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            padding: 8,
            border: '1px solid #ced4da',
            fontSize: 14
        }
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
        fontSize: 13
    },
    popperDisablePortal: {
        position: 'relative'
    },
    countryLabel: {
        fontSize: 16,
        fontWeight: 600,
        paddingLeft: 10,
        color: '#9d98b0'
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 5,
        width: 300,
        zIndex: 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#fff',
        margin: 0,
        padding: 10
    },
    header: {
        borderBottom: '1px solid #e1e4e8',
        padding: '8px 10px',
        fontWeight: 600
    }
})

const flagsPopper = (props: any) => {
    return <Popper {...props} style={{ width: 300 }} placement='bottom-start' />
}

const countryToFlag = (code: string, countryName: string) => {
    return (
        <img
            alt={countryName}
            height={24}
            width={24}
            src={'/img/svg/' + code + '.svg'}
            style={{ borderRadius: 2 }}
        />
    )
}

export interface CountryCodeAdornmentProps {
    setCountryCode?: (countryCode: string) => void
}

export const CountryCodeAdornment: React.FC<CountryCodeAdornmentProps> = ({
    setCountryCode
}) => {
    const classes = useStyles()

    const [value, setValue] = React.useState<CountryType>()
    const [inputValue, setInputValue] = React.useState<string>('+ 353')
    const [selectedCountryName, setSelectedCountryName] = React.useState<
        string
    >('Ireland')
    const [flagUrl, setFlagUrl] = React.useState<string>('/img/svg/ie.svg')

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [pendingValue, setPendingValue] = React.useState<CountryType>()

    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setPendingValue(value)
        setAnchorEl(event.currentTarget)
    }

    const handleOnChange = (event: any, values: CountryType | null) => {
        if (values && values.code) {
            setInputValue('+ ' + values.code)
            setCountryCode?.(values.code)
            setSelectedCountryName(values.name)
            setFlagUrl('/img/svg/' + values.abbr + '.svg')
        }
    }

    const handleClose = (
        event: React.ChangeEvent<{}>,
        reason: AutocompleteCloseReason
    ) => {
        if (reason === 'toggleInput') {
            return
        }
        setValue(pendingValue)
        if (anchorEl) {
            anchorEl.focus()
        }
        setAnchorEl(null)
    }

    return (
        <>
            <ButtonBase
                disableRipple
                aria-describedby={'flags'}
                onClick={handleClick}
            >
                <img
                    src={flagUrl}
                    alt={selectedCountryName}
                    width={24}
                    height={24}
                />
                <Text className={classes.countryLabel} label={inputValue} />
            </ButtonBase>
            <Popper
                id={'flags'}
                open={open}
                anchorEl={anchorEl}
                placement='bottom-start'
                className={classes.popper}
            >
                <Autocomplete
                    id='country-select-demo'
                    options={countryData}
                    classes={{
                        paper: classes.paper,
                        option: classes.option,
                        popperDisablePortal: classes.popperDisablePortal
                    }}
                    open
                    disablePortal
                    onClose={handleClose}
                    onChange={handleOnChange}
                    value={pendingValue}
                    PopperComponent={flagsPopper}
                    getOptionLabel={option => `${option.name} ${option.code} `}
                    noOptionsText='No country found'
                    renderOption={option => (
                        <React.Fragment>
                            <span>
                                {countryToFlag(option.abbr, option.name)}
                            </span>
                            {option.name} (+
                            {option.code})
                        </React.Fragment>
                    )}
                    renderInput={params => {
                        return (
                            <InputBase
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                autoFocus
                                className={classes.inputBase}
                            />
                        )
                    }}
                />
            </Popper>
        </>
    )
}
