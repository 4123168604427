import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { globalHistory } from '@reach/router'
import { APP_INSIGHTS_INSTRUMENTATION_KEY } from 'core'

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: APP_INSIGHTS_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        enableAjaxPerfTracking: true,
        extensionConfig: {
            [reactPlugin.identifier]: { history: globalHistory }
        }
    }
})
appInsights.loadAppInsights()
appInsights.trackPageView()
export { reactPlugin, appInsights }
