import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

export interface ContactLeftHeadingProps {
    title: string
    text: string
    heading: string
}

export const ContactLeftHeading: React.FC<ContactLeftHeadingProps> = ({
    text,
    title,
    heading
}) => {
    const desktop = useDesktop()
    return (
        <>
            <Text label={heading} variant={desktop ? 'h1' : 'h2'} component='h1'/>

            <Text label={title} variant={desktop ? 'h4' : 'h3'} component='h2'/>
            <Text
                box={{ mb: 6 }}
                variant='body2'
                color='textSecondary'
                label={text}
                component='p'
            />
        </>
    )
}
