import { Box, Typography } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { makeStyles } from '@material-ui/core/styles'
import { useDesktop } from 'common/hooks/useDesktop'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import TagManager from 'react-gtm-module'

export interface LoginProps {
    login: any
}

const Login: React.FC<LoginProps> = ({ login }) => {
    const desktop = useDesktop()

    const useStyles = makeStyles({
        ul: {
            padding: 0
        },
        item: {
            paddingLeft: '24px',
            paddingRight: '24px',
            fontSize: '16px',
            fontWeight: 500,
            color: '#717FB8',
            width: '100%',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid rgb(235, 237, 247)',
            '&:last-child': {
                borderBottom: 'none'
            }
        }
    })
    const classes = useStyles()

    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLButtonElement>(null)

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen)
    }

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return
        }

        setOpen(false)
    }

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }
    return (
        <Box color='primary.main'>
            <Typography
                style={{
                    color: open ? '#1D2F7C' : undefined,
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: 1.54,
                    cursor: 'pointer'
                }}
                variant='h3'
                onClick={() => {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'navigationClick',
                            clickText: 'Log in',
                            clickURL: '',
                            clickID: 'Log in'
                        }
                    })
                    handleToggle()
                }}
                ref={anchorRef}
            >
                Log in
            </Typography>
            <Popper
                style={{
                    marginTop: '20px',
                    marginRight: '45px',
                    filter: 'drop-shadow(0px 4px 10px rgba(58, 49, 96, 0.18))'
                }}
                open={open}
                anchorEl={anchorRef.current}
                placement={desktop ? 'bottom' : 'top'}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                        <Paper style={{ borderRadius: 0 }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    className={classes.ul}
                                    autoFocusItem={open}
                                    id='menu-list-grow'
                                    onKeyDown={handleListKeyDown}
                                >
                                    {_.map(login || [], (item: any, index) => (
                                        <a
                                            className={classes.item}
                                            key={index}
                                            rel='noopener noreferrer'
                                            href={item.url}
                                            target='_blank'
                                            onClick={e => {
                                                TagManager.dataLayer({
                                                    dataLayer: {
                                                        event:
                                                            'navigationClick',
                                                        clickText: item.label,
                                                        clickURL: item.url,
                                                        clickID: item.label
                                                    }
                                                })
                                                handleClose(e)
                                            }}
                                        >
                                            {item.label}
                                        </a>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    )
}

export default Login
