import { Box, Grid } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { useDesktop } from 'common/hooks/useDesktop'
import { FooterLinks } from 'common/utils/getFooterLinks'
import React from 'react'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { Contact, ContactProps } from '../Contact'
import { FooterFormLink } from './FooterFormLink'
import { FooterHeading } from './FooterHeading'
import { FooterLink } from './FooterLink'
import { FooterRegLine } from './FooterRegLine'
import { FooterSubheading } from './FooterSubheading'
import { ReactComponent as WhiteLogo } from './logo.svg'
import { Image } from '@chakra-ui/react'

export type FooterVariant = 'default' | 'form' | 'link' | 'slim' | 'careers'
export interface FooterProps {
    contactForm?: ContactProps | null
    links: FooterLinks
    regLine: string
    variant?: FooterVariant
}

export const Footer: React.FC<FooterProps> = ({ variant, ...data }) => {
    const desktop = useDesktop()

    const linearGradient = {
        background:
            'linear-gradient(251.27deg, #5C61AC 3.05%, #2C2D8B 101.04%)',
        clipPath:
            variant === 'careers'
                ? ''
                : 'polygon(0 48px, 100% 0, 100% 100%, 0% 100%)',
    }

    const textAlign = desktop
        ? undefined
        : ('center' as CSSProperties['textAlign'])

    const styles = {
        overline2: {
            fontWeight: 600,
            fontSize: 14,
            lineHeight: 16 / 14,
            letterSpacing: '0.05em',
            textAlign,
        },
        link2: {
            color: '#fff',
            fontWeight: 600,
            fontSize: 14,
            lineHeight: 20 / 14,
            textAlign,
        },
        link3: {
            color: '#EBEDF7',
            fontWeight: 600,
            fontSize: 12,
            lineHeight: 16 / 12,
            textAlign,
        },
    }
    return (
        <ReactVisibilitySensor partialVisibility>
            <Box component='aside'>
                {variant === 'form' || variant === 'slim' ? (
                    <>
                        {data?.contactForm && <Contact {...data.contactForm} />}
                    </>
                ) : variant === 'careers' ? null : (
                    <Spacer variant='md' />
                )}
                <Box style={linearGradient}>
                    {!(variant === 'careers') && (
                        <div
                            style={{
                                height: '100%',
                                background: '#5261AC',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '40px 0px',
                                flexWrap: 'wrap',
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '32px',
                                    color: '#FFF',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    margin: '20px',
                                }}
                            >
                                Proud Supporters
                            </span>
                            <Image
                                style={{
                                    maxWidth: '280px',
                                    height: 'auto',
                                    marginRight: '10px',
                                }}
                                src='https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/9038b0bd-a490-4d7c-b0f1-3b5e4434e365/IL_DM_WIDE_%28RGB%29.png'
                                alt='Dan Abramov'
                            />
                            <Image
                                style={{
                                    maxWidth: '125px',
                                    height: 'auto',
                                }}
                                src='https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/bc9a9ddf-a9d7-4009-a98b-cc19363d0b10/Logo%20-%20GAA%20Healthy%20Clubs%20cropped.png'
                                alt='Dan Abramov'
                            />
                        </div>
                    )}
                    <Box
                        color='#fff'
                        pt={desktop ? 28 : 24}
                        pb={desktop ? (variant === 'careers' ? 0 : 20) : 12}
                        // style={radialGradient}
                        maxWidth='100%'
                    >
                        {variant !== 'slim' && (
                            <Wrapper>
                                {variant === 'link' && <FooterFormLink />}

                                {!(variant === 'careers') ? (
                                    <Grid container spacing={6}>
                                        <Grid item xs={12} md={2}>
                                            <Box
                                                display='flex'
                                                justifyContent='center'
                                                mb={desktop ? 0 : 11}
                                            >
                                                <WhiteLogo />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FooterHeading label='About us' />
                                            {data?.links.aboutUs.map((link) => (
                                                <FooterLink
                                                    key={link.label}
                                                    {...link}
                                                />
                                            ))}
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FooterHeading label='Contact & Help' />
                                            {data?.links.contactAndHelp.map(
                                                (link) => (
                                                    <FooterLink
                                                        key={link.label}
                                                        {...link}
                                                    />
                                                )
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FooterHeading label='Products & Services' />
                                            <FooterSubheading
                                                label={
                                                    'Irish Life Assurance Products'
                                                }
                                            />
                                            {data?.links.assuranceProducts.map(
                                                (link) => (
                                                    <FooterLink
                                                        key={link.label}
                                                        {...link}
                                                    />
                                                )
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box mt={desktop ? 16 : 0} />
                                            <FooterSubheading label='Irish Life Health Products' />
                                            {data?.links.healthProducts.map(
                                                (link) => (
                                                    <FooterLink
                                                        key={link.label}
                                                        {...link}
                                                    />
                                                )
                                            )}
                                            <FooterSubheading label='Irish Life Financial Services' />
                                            {data?.links.financialServices.map(
                                                (link) => (
                                                    <FooterLink
                                                        key={link.label}
                                                        {...link}
                                                    />
                                                )
                                            )}
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container spacing={6}>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <Box
                                                display={desktop ? '' : 'flex'}
                                                justifyContent={
                                                    desktop ? '' : 'center'
                                                }
                                                mb={desktop ? 0 : 11}
                                            >
                                                <WhiteLogo />
                                            </Box>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={3}
                                            style={
                                                desktop
                                                    ? {
                                                          display: 'flex',
                                                          justifyContent:
                                                              'center',
                                                      }
                                                    : {}
                                            }
                                        >
                                            <div>
                                                <FooterHeading
                                                    label='Get in touch'
                                                    careers
                                                />
                                                {data?.links?.getInTouch?.map(
                                                    (link) => (
                                                        <FooterLink
                                                            key={link.label}
                                                            {...link}
                                                            icon={{
                                                                url: 'https://cdn-icons-png.flaticon.com/512/3178/3178158.png',
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={3}
                                            style={
                                                desktop
                                                    ? {
                                                          display: 'flex',
                                                          justifyContent:
                                                              'center',
                                                      }
                                                    : {}
                                            }
                                        >
                                            <div>
                                                <FooterHeading
                                                    label='Follow us'
                                                    careers
                                                />
                                                {data?.links?.followUs?.map(
                                                    (link) => (
                                                        <FooterLink
                                                            key={link.label}
                                                            {...link}
                                                            icon={{
                                                                url: 'https://cdn-icons-png.flaticon.com/512/3178/3178158.png',
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={3}
                                            style={
                                                desktop
                                                    ? {
                                                          display: 'flex',
                                                          justifyContent:
                                                              'center',
                                                      }
                                                    : {}
                                            }
                                        >
                                            <div>
                                                <FooterHeading
                                                    label='Working with Irish Life'
                                                    careers
                                                />
                                                {data?.links.workingWithIrish?.map(
                                                    (link) => (
                                                        <FooterLink
                                                            key={link.label}
                                                            {...link}
                                                            icon={{
                                                                url: 'https://cdn-icons-png.flaticon.com/512/3178/3178158.png',
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                )}
                            </Wrapper>
                        )}
                    </Box>
                    <Box
                        // bgcolor='linear-gradient(251.25deg, #00B1D9 3.05%, #1E58A5 24.48%, #2C2D8B 57.15%)'
                        color='#fff'
                        pt={12}
                        pb={24}
                    >
                        <Wrapper>
                            {variant === 'careers' && (
                                <Box
                                    borderTop='2px solid #FFFFFF'
                                    my={7}
                                    style={{ opacity: 0.1 }}
                                />
                            )}
                            <Box
                                display='flex'
                                flexDirection={desktop ? 'row' : 'column'}
                                justifyContent='space-between'
                            >
                                <Text
                                    box={{
                                        mb: desktop ? 0 : 6,
                                        mt: desktop ? 0 : 3,
                                    }}
                                    label='&copy; 2020 Irish Life Insurance'
                                    style={{
                                        ...styles.link3,
                                        color: '#C1C7E1',
                                    }}
                                />
                                {data?.links.bottom.map((link) => (
                                    <FooterLink
                                        key={link.label}
                                        {...link}
                                        variant='bottom'
                                    />
                                ))}
                            </Box>
                            <Box
                                borderTop='2px solid #FFFFFF'
                                my={7}
                                style={{ opacity: 0.1 }}
                            />
                            <FooterRegLine text={data?.regLine} />
                        </Wrapper>
                    </Box>
                </Box>
            </Box>
        </ReactVisibilitySensor>
    )
}
