import { CallToActionProps } from 'common/components/molecules/CallToAction'
import React from 'react'
import { DefaultSection } from './DefaultSection'

export interface FeaturedContentProps {
    callToAction?: CallToActionProps
    title: string
    text?: string
}

export const FeaturedContent: React.FC<FeaturedContentProps> = props => {
    return <DefaultSection {...props} />
}
