import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React, { CSSProperties } from 'react'

export interface FooterHeadingProps {
    label: string
    careers?: boolean
}

export const FooterHeading: React.FC<FooterHeadingProps> = ({
    label,
    careers,
}) => {
    const desktop = useDesktop()
    const textAlign = desktop
        ? undefined
        : ('center' as CSSProperties['textAlign'])
    const styles = {
        overline2: {
            fontWeight: 600,
            fontSize: careers ? 18 : 14,
            letterSpacing: '0.05em',
            lineHeight: 1.2,
            textAlign,
            textTransform: 'uppercase' as CSSProperties['textTransform'],
        },
    }

    return (
        <Text
            box={{
                mt: desktop ? 3 : 5,
                mb: desktop ? (careers ? 4 : 9) : careers ? 2 : 6,
            }}
            label={label}
            style={styles.overline2}
            component='h2'
        />
    )
}
