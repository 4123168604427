import { Box } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Link, useLocation } from '@reach/router'
import React from 'react'

export interface SecondaryNavigationProps {}

export const SecondaryNavigation: React.FC<SecondaryNavigationProps> = () => {
    const { pathname } = useLocation()

    const isCorporate = pathname.includes('/employer-solutions')

    return (
        <Box h='48px' bg='brand.200' display='flex' px='48px'>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '26px',
                    mt: '4px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        height: '32px',
                        padding: '8px 0px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderBottom: !isCorporate && '2px solid #5E6CDA',
                    }}
                >
                    <Text
                        variant='label-sm'
                        color='monochrome.800'
                        sx={{
                            fontSize: '16px',
                            fontWeight: '600',
                            lineHeight: '20px',
                        }}
                    >
                        {(isCorporate && <Link to='/'>Personal</Link>) ||
                            'Personal'}
                    </Text>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        height: '32px',
                        padding: '8px 0px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderBottom: isCorporate && '2px solid #5E6CDA',
                    }}
                >
                    <Text
                        variant='label-sm'
                        color='monochrome.800'
                        sx={{
                            fontSize: '16px',
                            fontWeight: '600',
                            lineHeight: '20px',
                        }}
                    >
                        {(!isCorporate && (
                            <Link to='/employer-solutions'>Employer</Link>
                        )) ||
                            'Employer'}
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}
