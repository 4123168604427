import { Box, Button, Grid, Hidden } from '@material-ui/core'
import { navigate } from '@reach/router'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import TagManager from 'react-gtm-module'
import { ReactComponent as ArrowRightCutout } from './ArrowRightCutout.svg'
export interface FooterFormLinkProps {}

export const FooterFormLink: React.FC<FooterFormLinkProps> = props => {
    const desktop = useDesktop()
    return (
        <Grid container spacing={6}>
            <Hidden>
                <Grid item xs={2} />
            </Hidden>
            <Grid item xs={12} md={8}>
                <Text
                    align='center'
                    box={{
                        mb: desktop ? 9 : 12
                    }}
                    html
                    label='<p>Got any questions?<br/>We’re here to help.</p>'
                    variant='h1'
                />
                <Box mb={24} display='flex' justifyContent='center'>
                    <Button
                        endIcon={<ArrowRightCutout />}
                        onClick={() => {
                            navigate('/contact-us')
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'primaryButtonClick',
                                    clickText: 'Get in touch',
                                    clickURL: '/contact-us',
                                    clickID: 'Get in touch'
                                }
                            })
                        }}
                        size='large'
                        style={{
                            background: 'rgba(255, 255, 255, 0.2)',
                            borderRadius: 36,
                            color: '#fff',
                            fontWeight: 600,
                            fontSize: 16,
                            height: 50,
                            lineHeight: 1.2,
                            textTransform: 'unset'
                        }}
                    >
                        Get in touch
                    </Button>
                </Box>
            </Grid>
            <Hidden>
                <Grid item xs={2} />
            </Hidden>
        </Grid>
    )
}
