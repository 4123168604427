import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

export interface ContactRightHeadingProps {
    title: string
    description: string
}

export const ContactRightHeading: React.FC<ContactRightHeadingProps> = ({
    title,
    description
}) => {
    const desktop = useDesktop()
    return (
        <>
            <Spacer variant={!desktop ? 'md' : undefined} />
            <Text
                box={{ mt: desktop ? 12 : 0, mb: 7 }}
                label={title}
                variant={desktop ? 'h4' : 'h3'}
                component='h2'
            />
            <Text
                box={{ mb: desktop ? 24 : 8 }}
                color='textSecondary'
                html
                label={description}
                variant='subtitle1'
                component='p'
            />
        </>
    )
}
