import { BasicButton } from 'common/components/atoms/BasicButton'
import React from 'react'

export interface JumbotronCallToActionProps {
    analyticsId: string
    endIcon?: string
    label: string
    jumbotronLabel?: string
    labelMobile?: string
    onClick?: () => void
    noSpacer?: boolean
    startIcon?: string
    size?: any
    title?: string
    titleMobile?: string
    text?: string
    url?: string
    jumbotronUrl?: string
    variant?:
        | 'contained'
        | 'secondary'
        | 'outlined'
        | 'floating'
        | 'bright'
        | 'dual'
        | 'ghost'
}

export const JumbotronCallToAction: React.FC<JumbotronCallToActionProps> = ({
    variant = 'contained',
    ...props
}) => {
    const {
        analyticsId,
        endIcon,
        label,
        jumbotronLabel,
        onClick,
        noSpacer,
        startIcon,
        size = 'lg',
        url = '',
        jumbotronUrl = '',
    } = props

    return (
        <>
            {variant !== 'floating' && (
                <BasicButton
                    variant={variant}
                    label={jumbotronLabel || label}
                    url={jumbotronUrl || url}
                    analyticsId={analyticsId}
                    noSpacer={noSpacer}
                    size={size}
                    startIcon={startIcon}
                    endIcon={endIcon}
                    onClick={onClick}
                />
            )}
        </>
    )
}
