import { Link as RouterLink } from '@reach/router'
import { useBrowser } from 'common/hooks/useBrowser'
import React from 'react'
//Test
interface LinkProps {
    onClick?: () => void
    path: string
    helpcentre?: boolean
}

export const Link: React.FC<LinkProps> = ({
    children,
    onClick,
    path,
    helpcentre,
}) => {
    const isBrowser = useBrowser()
    if (!path)
        return React.cloneElement(children as React.ReactElement, { onClick })
    const isExternal = /^(https?:|mailto:|tel:)/.test(path)
    const isAnchor = path.includes('#') && !helpcentre
    const onClickFunction = () => {
        if (onClick) onClick()
    }

    if (isAnchor) {
        const anchor = path.split('#')[1]
        let el: any = isBrowser && document.getElementById(anchor)
        return (
            <a
                data-testid='Link'
                onClick={(e) => {
                    e.preventDefault()
                    onClickFunction()
                    if (el !== null) {
                        el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                        })
                    }
                }}
                href={path}
                style={{
                    marginInlineStart: '0px !important',
                    WebkitMarginStart: '0px !important',
                    marginLeft: '-8px',
                }}
            >
                {children}
            </a>
        )
    }

    return isExternal || isAnchor ? (
        <a
            data-testid='Link'
            onClick={onClickFunction}
            href={path}
            style={{
                marginInlineStart: '0px !important',
                WebkitMarginStart: '0px !important',
                marginLeft: '-8px',
            }}
        >
            {children}
        </a>
    ) : (
        <RouterLink
            data-testid='Link'
            onClick={onClickFunction}
            to={path}
            style={{
                marginInlineStart: '0px !important',
                WebkitMarginStart: '0px !important',
                marginLeft: '-8px',
            }}
        >
            {children}
        </RouterLink>
    )
}
