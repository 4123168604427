import { JumbotronCallToActionProps } from 'common/components/organisms/Jumbotron/JumbotronCallToAction'

export const getButtonColor = (
    variant: JumbotronCallToActionProps['variant']
) => {
    switch (variant) {
        case 'contained':
        case 'dual':
            return 'primary'
        case 'secondary':
            return 'secondary'
        default:
            return undefined
    }
}
