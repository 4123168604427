import { Box } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import React from 'react'

export interface FooterRegLineProps {
    text: string
}

export const FooterRegLine: React.FC<FooterRegLineProps> = ({ text }) => {
    return (
        <Box mt={3} color='#C1C7E1'>
            <Text html label={text} variant='caption'></Text>
        </Box>
    )
}
