import moment from 'moment'
import {
    LEAD_GENERATION_API_KEY,
    LEAD_GENERATION_SUBSCRIPTION_KEY,
    LEAD_GENERATION_URL
} from 'core/config/env'

export interface genesysApiValues {
    // interactionId?: string
    // sourceName?: string
    // mobilePhone?: string
    // createdBy?: string
    // createdById?: string
    // firstName?: string
    // lastName?: string
    // areaOfInterest?: string
    // createGenesysCallback?: boolean
    // queueName?: string
    // callbackScheduledTime?: string // ISO date string
    // source?: string
    // leadSubSource?: string
    // topic?: string
    // originatingsource?: string
    // description?: string
}


const apiTimeSlots = new Map([
    [1,8],
    [2,9],
    [3,10],
    [4,11],
    [5,12],
    [6,13],
    [7,14],
    [8,15],
    [9,16],
    [10,17],
    [11,18],
    [12,19]
])

let headers = {
    'Content-Type': 'application/json',
    'Accept': '*/*',
    'API_KEY': LEAD_GENERATION_API_KEY,
    'Ocp-Apim-Subscription-Key': LEAD_GENERATION_SUBSCRIPTION_KEY,
    'Scope': 'api://retail-lead-callback-prod/.default'
}

const apimUrl = LEAD_GENERATION_URL

export const createGenesysCallback = async (token: any, fields: any) => {

    const names = processFullName(fields.fullName)

    const requestBody = {
        interactionId: "00000000-0000-0000-0000-000000000000",
        sourceName: "Retail",
        mobilePhone: fields.phoneNumber,
        firstName: names.firstName,
        lastName: names.lastName,
        emailAddress: "",
        description: `Callback submitted from ${window.location.href}`,
        createGenesysCallback: true,
        callbackScheduledTime: getGenesysAppointmentTime(fields.timeSlot),
        source: "Call back",
        areaOfInterest: "971180005",
        leadSourceName: "857190005",
        leadSubSource: "857190043",
        topic: "857190005",
        queuerequest: true
    }

    const result = await fetch(apimUrl, {
        headers: {...headers, 'Authorization': `${token.token}`},
        body: JSON.stringify(requestBody),
        method: 'post'
    })

    return await result.json()
}

// Function to get a new date
const getGenesysAppointmentTime = (key: any) => {

    let time = apiTimeSlots.get(key)

    if(moment(Date.now()).isDST()) {
        time !== undefined ? time += 1 : time = 0
    }

    const appointment = moment(Date.now()).hours(time || 1).minutes(0).seconds(0).format()

    return new Date(appointment).toISOString()

}


const processFullName = (fullName: string) => {
    // Extract name segments from single text field
    const names = fullName.split(' ')

    return {
        firstName: names.slice(0, (names.length - 1)).join(' '),
        lastName: names[names.length - 1]
    }


}