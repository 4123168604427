import { Box as CUIBox } from '@chakra-ui/react'
import { Collapse } from '@chakra-ui/transition'
import {
    NotificationBanner,
    NotificationBannerProps,
} from '@irishlife/ilgroupdesignsystem.molecules.notification-banner'
import { Box, BoxProps } from '@material-ui/core'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { navigate } from '@reach/router'
import { Metadata, MetadataProps } from 'common/components/atoms/Metadata'
import {
    // WebchatButton,
    WebchatButtonProps,
} from 'common/components/molecules/WebchatButton'
import { useDesktop } from 'common/hooks/useDesktop'
import { reactPlugin } from 'common/services/appInsights'
import { PageItem } from 'common/utils/getSiteSearchData'
import React, { useState } from 'react'
import TagManager from 'react-gtm-module'
import { Breadcrumbs, BreadcrumbsProps } from './Breadcrumbs'
import { Footer, FooterProps } from './Footer'
import { JumbotronProps } from './Jumbotron'
import { Navbar, Navigation } from './Navbar'

export interface BannerProps {
    variant?: string
    title?: string
    callbackLabel?: string
    clickURL?: string
    clickID?: string
    description?: string
}
export interface LayoutProps {
    box?: BoxProps
    breadcrumbs: BreadcrumbsProps
    footer: FooterProps
    login: any
    metadata: MetadataProps
    navigation: Navigation
    noBreadcrumbs?: boolean
    jumbotronVariant?: JumbotronProps['variant']
    webchat: WebchatButtonProps
    siteSearchData: PageItem[]
    banner?: BannerProps
    customNavigationMenu?: Navigation
}

export const Layout: React.FC<LayoutProps> = (props) => {
    const {
        box,
        breadcrumbs,
        children,
        footer,
        login,
        metadata,
        banner,
        navigation,
        noBreadcrumbs = false,
        jumbotronVariant,
        siteSearchData,
    } = props

    const [show, setShow] = useState(true)
    const handleClose = (): any => {
        setShow(false)
    }
    const handleClick = (): any => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'primaryButtonClick',
                clickText: banner?.callbackLabel,
                clickURL: banner?.clickURL,
                clickID: banner?.clickID,
            },
        })
        navigate(banner?.clickURL || '')
    }
    const desktop = useDesktop()
    const getNotificationBanner = (data: any): NotificationBannerProps => {
        return {
            title: data.title,
            callbackLabel: data.callbackLabel,
            maxWidth: '75vw',
            closeCallback: handleClose,
            callback: handleClick,
            description: data.description,
        }
    }
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <Box
                data-testid='Layout'
                component='main'
                overflow='hidden'
                {...box}
            >
                <Metadata {...metadata} />
                <Navbar
                    navigation={navigation}
                    login={login}
                    jumbotronVariant={jumbotronVariant}
                    siteSearchData={siteSearchData}
                />
                {(banner?.variant === 'dual' ||
                    banner?.variant === 'floating') &&
                    show && (
                        <CUIBox paddingTop={'80px'}>
                            <Collapse animateOpacity={true} in={show}>
                                <CUIBox
                                    width={'100vw'}
                                    position={'fixed'}
                                    left={0}
                                    top={desktop ? '80px' : '54px'}
                                    backgroundColor={'#E3E5F9'}
                                    display={'flex'}
                                    flexDirection={'row'}
                                    justifyContent={'center'}
                                    zIndex={29}
                                    fontWeight={600}
                                >
                                    <NotificationBanner
                                        {...getNotificationBanner(banner)}
                                    />
                                </CUIBox>
                            </Collapse>
                        </CUIBox>
                    )}

                <CUIBox
                    paddingTop={
                        !desktop &&
                        show &&
                        (banner?.variant === 'dual' ||
                            banner?.variant === 'floating')
                            ? '2.75rem'
                            : ''
                    }
                    mt='48px'
                >
                    {!noBreadcrumbs ? (
                        breadcrumbs.jumbotronVariant !== 'home' &&
                        breadcrumbs.jumbotronVariant !== 'background' && (
                            <Breadcrumbs
                                {...breadcrumbs}
                                jumbotronVariant={jumbotronVariant}
                            />
                        )
                    ) : (
                        <CUIBox h={desktop ? '80px' : '54px'} />
                    )}
                    {children}
                </CUIBox>
                {/* <Spacer /> */}
                <Footer {...footer} />
            </Box>
        </AppInsightsContext.Provider>
    )
}
