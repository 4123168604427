import { Grid } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { ReactComponent as HamburgerClose } from './Hamburger_Close.svg'
import { ReactComponent as InfoCircle } from './Info_Circle.svg'
import { ReactComponent as TickFadedLarge } from './Tick_Faded_Lrg.svg'

export interface ContactConfirmationProps {
    title: string
    subTitle: string
    description: string
    noteMessage: string
    toggleConfirmation?: () => void
}

export const ContactConfirmation: React.FC<ContactConfirmationProps> = ({
    title,
    subTitle,
    description,
    noteMessage,
    toggleConfirmation
}) => {
    const desktop = useDesktop()

    return (
        <>
            <Grid container xs={12} sm={12} lg={12}>
                <Grid container xs={12} sm={12} lg={12} justify='center'>
                    <Grid item xs={11} sm={11} lg={11} justify='center'>
                        <TickFadedLarge style={{ marginBottom: '24' }} />
                    </Grid>
                    <Grid item xs={1} sm={1} lg={1} justify='center'>
                        <HamburgerClose
                            height='24'
                            width='24'
                            style={{
                                marginTop: '-30px',
                                marginLeft: desktop ? '30px' : '',
                                cursor: 'pointer'
                            }}
                            onClick={toggleConfirmation}
                        />
                    </Grid>
                </Grid>
                <Grid item lg={12}>
                    <Text label={title} variant='h4' />
                    <Text
                        box={{ mb: desktop ? 12.5 : 8 }}
                        label={subTitle}
                        variant='body2'
                    />
                    <Text
                        html
                        color='textSecondary'
                        box={{ mb: desktop ? 14 : 22.2 }}
                        variant='h6'
                        label={description}
                    />
                </Grid>
            </Grid>

            <Grid container xs={12} sm={12} lg={12}>
                <Grid container xs={12} sm={12} lg={1}>
                    <InfoCircle
                        height={24}
                        width={24}
                        style={{ marginBottom: desktop ? '' : '27px' }}
                    />
                </Grid>
                <Grid container xs={12} sm={12} lg={11}>
                    <Text
                        html
                        box={{ mb: desktop ? 18 : 13.6 }}
                        variant='body2'
                        label={noteMessage}
                    />
                </Grid>
            </Grid>
        </>
    )
}
