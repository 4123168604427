import { Box, Fab, FabProps, useTheme } from '@material-ui/core'
import { Link } from 'common/components/atoms/Link'
import { useDesktop } from 'common/hooks/useDesktop'
import React, { CSSProperties } from 'react'
import TagManager from 'react-gtm-module'
import { getButtonColor } from './getButtonColor'
import { GhostButton } from './GhostButton'

const buttonStyles = {
    large: { height: 60 },
    medium: { height: 44 },
    small: { height: 32 },
}

export interface BasicButtonProps {
    analyticsId: string
    analyticsEventName?: string
    endIcon?: string
    label?: string
    labelMobile?: string
    onClick?: () => void
    noSpacer?: boolean
    startIcon?: string
    size?: FabProps['size']
    title?: string
    titleMobile?: string
    text?: string
    url?: string
    variant?:
        | 'contained'
        | 'secondary'
        | 'outlined'
        | 'floating'
        | 'bright'
        | 'dual'
        | 'ghost'
}

export const BasicButton: React.FC<BasicButtonProps> = ({
    variant = 'contained',
    size = 'large',
    analyticsEventName = 'primaryButtonClick',
    ...props
}) => {
    const theme = useTheme()
    const desktop = useDesktop()
    const [hover, setHover] = React.useState(false)
    const {
        analyticsId,
        endIcon,
        label,
        onClick,
        noSpacer,
        startIcon,
        url = '',
    } = props

    const sharedStyles: CSSProperties = {
        ...buttonStyles[size],
        alignSelf: 'flex-start',
        borderRadius: 36,
        boxShadow: 'none',
        fontWeight: 600,
        textTransform: 'unset',
        minWidth: '60px',
    }

    const styles: { [index: string]: CSSProperties } = {
        contained: {
            ...sharedStyles,
        },
        secondary: {
            ...sharedStyles,
        },
        outlined: {
            ...sharedStyles,
            backgroundColor: '#F7F8FB',
            border: '2px solid #5261AC',
            color: theme.palette.primary.main,
            padding: '15px 32px',
        },
        bright: {
            ...sharedStyles,
            background: '#fff',
            color: '#5261AC',
            filter: 'drop-shadow(0px 0px 16px rgba(255, 255, 255, 0.48))',
            lineHeight: 'normal',
        },
        dual: {
            ...sharedStyles,
        },
    }

    const fakeColor = {
        default:
            'invert(43%) sepia(91%) saturate(2132%) hue-rotate(216deg) brightness(88%) contrast(92%)',
        hover: 'invert(100%) sepia(0%) saturate(2%) hue-rotate(338deg) brightness(106%) contrast(101%)',
    }

    return (
        <>
            {variant !== 'floating' && (
                <>
                    {!noSpacer && variant !== 'bright' && (
                        <Box mt={desktop ? 9 : 8} />
                    )}
                    <Link
                        onClick={() => {
                            if (onClick) onClick()
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: analyticsEventName,
                                    clickText: label,
                                    clickURL: url,
                                    clickID: analyticsId,
                                },
                            })
                        }}
                        path={url}
                    >
                        {variant === 'ghost' ? (
                            <GhostButton {...props} />
                        ) : (
                            <Fab
                                data-testid='basicButton'
                                onMouseEnter={() => {
                                    setHover(true)
                                }}
                                onMouseLeave={() => {
                                    setHover(false)
                                }}
                                color={getButtonColor(variant)}
                                size={size || 'large'}
                                style={styles[variant]}
                                variant='extended'
                            >
                                {startIcon && (
                                    <img
                                        alt='Left Icon'
                                        src={startIcon}
                                        style={{
                                            marginRight: '8px',
                                            marginLeft: '-8px',
                                            width: '28px',
                                            height: '28px',
                                        }}
                                    />
                                )}
                                {label}
                                {endIcon && (
                                    <img
                                        alt='Right Icon'
                                        src={endIcon}
                                        style={{
                                            filter:
                                                variant === 'secondary'
                                                    ? fakeColor[
                                                          hover
                                                              ? 'hover'
                                                              : 'default'
                                                      ]
                                                    : undefined,
                                            marginLeft: '8px',
                                            marginRight: '-8px',
                                            width: '28px',
                                            height: '28px',
                                        }}
                                    />
                                )}
                            </Fab>
                        )}
                    </Link>
                </>
            )}
        </>
    )
}
