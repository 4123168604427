import { List } from '@material-ui/core'
import { useDesktop } from 'common/hooks/useDesktop'
import _ from 'lodash'
import React from 'react'
import { NavigationPages } from '../interfaces'
import { NavbarMenuListItem } from './NavbarMenuListItem'

export interface NavbarMenuListProps {
    level?: number
    pages?: NavigationPages
}

export const NavbarMenuList: React.FC<NavbarMenuListProps> = ({
    level = 1,
    pages
}) => {
    const desktop = useDesktop()

    if (!pages) return null
    const last = Object.keys(pages)[Object.keys(pages).length - 1]
    return (
        <List disablePadding={!desktop}>
            {_.map(
                pages,
                (page, key) =>
                    page && (
                        <NavbarMenuListItem
                            page={page}
                            key={key}
                            level={level}
                            last={key === last}
                        />
                    )
            )}
        </List>
    )
}
