import { useState, useEffect } from 'react'

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState([1024, 954])
    useEffect(() => {
        if (window) {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight])
            }

            window.addEventListener('resize', handleWindowResize)
            handleWindowResize()
            return () => {
                window.removeEventListener('resize', handleWindowResize)
            }
        }
    }, [])

    return windowSize
}
