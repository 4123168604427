import { ZenDeskAnonymousTicket } from 'common/components/organisms/Contact/ContactLeft/ContactLeftFields'

const zenDeskBaseApiUrl = 'https://irishlife.zendesk.com/api'

export const createAnonymousTicket = async (ticket: ZenDeskAnonymousTicket) => {
    const headers = {
        'Content-Type': 'application/json'
    }

    let zenDeskticket = {
        request: {
            requester: { name: ticket.requesterName },
            type: ticket.type,
            subject: ticket.subject,
            tags: ticket.tags,
            custom_fields: [
                { id: 360004530177, value: ticket.phoneNumber }, // phone number custom field
                { id: 360004530197, value: [ticket.timeToCall] } // time to call custom field
            ],
            comment: { body: ticket.commentText }
        }
    }

    fetch(zenDeskBaseApiUrl + '/v2/requests.json', {
        headers: headers,
        method: 'post',
        body: JSON.stringify(zenDeskticket)
    })
}
