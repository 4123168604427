import { Box, useTheme } from '@material-ui/core'
import { FormatShapes } from '@material-ui/icons'

import React from 'react'

export interface IconProps {
    icon?: React.ReactElement
}

export const Icon: React.FC<IconProps> = ({ icon }) => {
    const { wireframe } = useTheme()
    return (
        <Box data-testid='Icon'>{(!wireframe && icon) || <FormatShapes />}</Box>
    )
}
