import {
    Box,
    Button,
    ButtonProps,
    CardMedia,
    FabProps,
    useTheme
} from '@material-ui/core'
import React, { CSSProperties } from 'react'

const buttonStyles = {
    large: { height: 60 },
    medium: { height: 42 },
    small: { height: 33 }
}

export interface CallToActionProps extends ButtonProps {
    endIcon?: string
    startIcon?: string
    size?: FabProps['size']
    text?: string
    variant?: 'contained' | 'outlined'
}

export const CallToAction: React.FC<CallToActionProps> = ({
    endIcon,
    startIcon,
    size = 'medium',
    text,
    variant = 'contained'
}) => {
    const theme = useTheme()

    const sharedStyles: CSSProperties = {
        ...buttonStyles[size],
        borderRadius: 36,
        boxShadow: 'none',
        textIndent: 10,
        textTransform: 'unset',
        alignSelf: 'flex-start'
    }

    const styles: { [index: string]: CSSProperties } = {
        contained: {
            ...sharedStyles
        },
        outlined: {
            ...sharedStyles,
            backgroundColor: '#EBEDF7',
            color: theme.palette.primary.main,
            padding: '15px 32px'
        },
        icon: {
            height: 24,
            width: 24
        }
    }

    return (
        <Box data-testid='CallToAction'>
            <Button
                style={styles[variant]}
                variant='outlined'
                color={variant === 'contained' ? 'primary' : undefined}
                size={size || 'large'}
                startIcon={
                    <CardMedia
                        style={styles.icon}
                        image={startIcon || endIcon}
                    />
                }
            >
                {text}
            </Button>
        </Box>
    )
}
