import { Box } from '@material-ui/core'
import React from 'react'
import { NavbarDesktop } from './NavbarDesktop'
import { MainNavigationProps } from './NavbarDesktop/MainNavigation'

export interface NavbarProps extends MainNavigationProps {}

export const Navbar: React.FC<NavbarProps> = (props) => {
    return (
        <Box data-testid='Navbar'>
            <NavbarDesktop {...props} />
        </Box>
    )
}
