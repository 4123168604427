import React from 'react'
import { Box } from '@material-ui/core'
import { Branding } from './Branding'
import { MainNavigation, MainNavigationProps } from './MainNavigation'

export interface NavbarDesktopProps extends MainNavigationProps { }

export const NavbarDesktop: React.FC<NavbarDesktopProps> = props => {
    return (
        <Box data-testid='NavbarDesktop' display='flex'>
            <Branding />
            <MainNavigation {...props} />
        </Box>
    )
}
