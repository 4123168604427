import { ATTRIBUTION_URL } from '../../core/config'

export interface AttributionLeadData {
    fullName?: string
    phoneNumber?: number
    savedFrom?: string
    email?: string
}

export const submitAttributionLead = async (leadData: AttributionLeadData) => {
    const headers = {
        'Content-Type': 'application/json'
    }

    let lead = {
        savedFrom: leadData.savedFrom,
        fullName: leadData.fullName,
        phoneNumber: leadData.phoneNumber,
        email: leadData.email
    }

    fetch(`${ATTRIBUTION_URL}create/lead`, {
        headers: headers,
        method: 'post',
        body: JSON.stringify(lead)
    })
}
