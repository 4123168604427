import { Box } from '@material-ui/core'
import React from 'react'

export interface WrapperProps {
    id?: string
    variant?: 'boxed' | 'extended' | 'fullWidth'
    style?: object
    component?: any
}

export const Wrapper: React.FC<WrapperProps> = ({
    id,
    variant = 'boxed',
    component,
    children,
    style,
}) => {
    return (
        <Box
            id={id}
            data-testid='Wrapper'
            display='flex'
            justifyContent='center'
            style={style}
            component={component ? component : 'div'}
        >
            <Box mx={[0, 0, 6, 8]} width={['100%', '100%', '100%', 928, 1128]}>
                {children}
            </Box>
        </Box>
    )
}
