import { Box } from '@material-ui/core'
import { Spacer } from 'common/components/molecules/Spacer'
import { useDesktop } from 'common/hooks/useDesktop'
import React, { useState } from 'react'
import {
    ContactConfirmation,
    ContactConfirmationProps
} from './ContactConfirmation'
import { ContactLeftFields, ContactLeftFieldsProps } from './ContactLeftFields'
import { ContactLeftHeading } from './ContactLeftHeading'

export interface ContactLeftProps extends ContactLeftFieldsProps {
    confirmation: ContactConfirmationProps
}

export const ContactLeft: React.FC<ContactLeftProps> = ({
    callToAction,
    formValues,
    confirmation
}) => {
    const desktop = useDesktop()
    const [showConfirmation, setshowConfirmation] = useState(false)
    const toggleConfirmation = () => {
        setshowConfirmation(!showConfirmation)
    }
    return (
        <Box
            bgcolor='#fff'
            pt={desktop ? 12 : 16}
            px={desktop ? 15 : 7.8}
            mx={desktop ? [0, 0, -7.8] : -6}
        >
            {!showConfirmation && (
                <>
                    <ContactLeftHeading
                        text={formValues.descriptionForm}
                        title={formValues.titleForm}
                        heading={formValues.heading}
                    />

                    <ContactLeftFields
                        callToAction={callToAction}
                        formValues={formValues}
                        toggleConfirmation={toggleConfirmation}
                    />
                    {!desktop && <Spacer variant='md' />}
                </>
            )}
            {showConfirmation && (
                <>
                    <ContactConfirmation
                        {...confirmation}
                        toggleConfirmation={toggleConfirmation}
                    />
                    {!desktop && <Spacer variant='md' />}
                </>
            )}
        </Box>
    )
}
