import { IconButton } from '@irishlife/ilgroupdesignsystem.atoms.icon-button'
import { SearchIcon } from '@irishlife/ilgroupdesignsystem.icons'
import { SiteSearchModal } from '@irishlife/ilgroupdesignsystem.organisms.site-search-modal'
import { Box } from '@material-ui/core'
import { Link } from '@reach/router'
import axios from 'axios'
import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent'
import { PageItem } from 'common/utils/getSiteSearchData'
import React from 'react'
import { useQuery } from 'react-query'
import { atom, useRecoilState } from 'recoil'

const searchQuery = atom({
    key: 'searchQuery',
    default: '',
})

type SiteSearchProps = {
    siteSearchData: PageItem[]
}

export function SiteSearch({ siteSearchData = [] }: SiteSearchProps) {
    const { data: searchData } = useQuery<PageItem[]>(
        'siteSearch',
        async () => {
            const { data, headers } = await axios.get('/siteSearchData.json')
            const contentType = headers['content-type']
            if (contentType && contentType.includes('application/json')) {
                return data
            }
            return []
        },
        {
            refetchOnWindowFocus: false,
        }
    )

    const [defaultQuery, setDefaultQuery] = useRecoilState(searchQuery)
    const query = React.useRef(defaultQuery)
    const onSearchQueryChange = React.useCallback(
        (newQuery: string) => {
            setDefaultQuery(newQuery)
        },
        [setDefaultQuery]
    )

    React.useEffect(() => {
        query.current = defaultQuery
    }, [defaultQuery])
    if (!searchData?.length && !siteSearchData.length) {
        return null
    }
    return (
        <Box onClick={() => fireAnalyticsEvent({ name: 'siteSearchClick' })}>
            <SiteSearchModal
                defaultSearchQuery={query.current}
                onSearchQueryChange={onSearchQueryChange}
                siteData={searchData || siteSearchData || []}
                linkAs={Link}
            >
                <IconButton
                    size='sm'
                    aria-label='search-site'
                    variant='ghost'
                    icon={<SearchIcon fontSize={18} />}
                />
            </SiteSearchModal>
        </Box>
    )
}
