import { Box } from '@material-ui/core'
import {
    Breadcrumbs as BreadcrumbsMolecule,
    BreadcrumbsProps as BreadcrumbsMoleculeProps
} from 'common/components/molecules/Breadcrumbs'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { JumbotronProps } from 'common/components/organisms/Jumbotron'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

export interface BreadcrumbsProps extends BreadcrumbsMoleculeProps {
    jumbotronVariant?: JumbotronProps['variant']
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
    breadcrumbs,
    jumbotronVariant
}) => {
    const desktop = useDesktop()
    const desktopBg = ['image', 'campaign', 'flat'].includes(
        jumbotronVariant || ''
    )
        ? undefined
        : '#fff'
    const mobileBg = ['campaign', 'flat'].includes(jumbotronVariant || '')
        ? undefined
        : '#fff'

    return (
        <Box className='breadcrumbs-box' bgcolor={desktop ? desktopBg : mobileBg}>
            <Wrapper>
                <Box maxWidth={1128} component='section'>
                    <BreadcrumbsMolecule breadcrumbs={breadcrumbs} />
                </Box>
            </Wrapper>
        </Box>
    )
}
