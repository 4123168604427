import { Spacer } from 'common/components/molecules/Spacer'
import { SiteSearch } from 'common/components/organisms/SiteSearch'
import { PageItem } from 'common/utils/getSiteSearchData'
import React, { Dispatch, SetStateAction } from 'react'
import { GetAdviceButton } from '../../GetAdviceButton'
import { Navigation } from '../../interfaces'
import Login from '../../NavbarDesktop/Login'
import { ReactComponent as Shard } from '../../NavbarDesktop/shardMobile.svg'
import { NavbarMenuList } from '../../NavbarMenu/NavbarMenuList'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    VStack,
    Box as CUIBox,
} from '@chakra-ui/react'
export interface INavbarMobileMenuProps {
    open?: boolean
    login: any
    navigation: Navigation
    setOpen: Dispatch<SetStateAction<boolean>>
    siteSearchData: PageItem[]
}

export const NavbarMobileMenu: React.FC<INavbarMobileMenuProps> = ({
    open = true,
    login,
    navigation,
    setOpen,
    siteSearchData,
}) => {
    return (
        <CUIBox data-testid='NavbarMobileMenu'>
            <Modal
                autoFocus={false}
                closeOnOverlayClick={true}
                isOpen={open}
                onClose={() => setOpen(false)}
                size={'full'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <CUIBox
                            zIndex={2200}
                            position='absolute'
                            top={0}
                            left={0}
                        >
                            <Shard />
                        </CUIBox>
                    </ModalHeader>
                    <ModalCloseButton
                        size='lg'
                        sx={{ mr: 1, color: 'brand.700' }}
                    />
                    <ModalBody>
                        <Spacer variant='sm' />
                        <NavbarMenuList pages={navigation} />
                        <CUIBox
                            borderTop='1px solid #C1C7E1'
                            width='100%'
                            style={{ opacity: 0.2 }}
                        />
                    </ModalBody>
                    <ModalFooter width={175} verticalAlign={'start'}>
                        <VStack align='left' sx={{ ml: 6 }} spacing={6}>
                            <CUIBox>
                                <SiteSearch siteSearchData={siteSearchData} />
                            </CUIBox>
                            <CUIBox>
                                <GetAdviceButton />
                            </CUIBox>
                            <CUIBox>
                                <Login login={login} />
                            </CUIBox>
                        </VStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </CUIBox>
    )
}
