import { Box, Link } from '@material-ui/core'
import { Link as RouterLink } from '@reach/router'
import { BreadcrumbsProps } from 'common/components/molecules/Breadcrumbs'
import React from 'react'
import { Spacer } from '../Spacer'
import { ReactComponent as ArrowBreadcrumb } from './ArrowBreadcrumb.svg'

export interface BreadcrumbsMobileProps extends BreadcrumbsProps {}

export const BreadcrumbsMobile: React.FC<BreadcrumbsMobileProps> = ({
    breadcrumbs,
}) => {
    const { name, url } =
        breadcrumbs.length >= 2
            ? breadcrumbs[breadcrumbs.length - 2]
            : breadcrumbs[breadcrumbs.length - 1]

    return (
        <Box>
            <Box mt={14} />
            <Spacer variant='sm' />
            <Box display='flex' alignItems='center'>
                <ArrowBreadcrumb style={{ marginLeft: -6 }} />
                <Link
                    component={RouterLink}
                    key={url}
                    to={url}
                    style={{ color: '#766F90', fontSize: 12, marginLeft: 8 }}
                >
                    {name}
                </Link>
            </Box>
            <Box mt={9} />
        </Box>
    )
}
