import { Box } from '@material-ui/core'
import { Link } from '@reach/router'
import { useWindowSize } from 'common/hooks/useWindowSize'
import React from 'react'
import shardDesktop from './shardDesktop.svg'
import shardMobile from './shardMobile.svg'
export interface BrandingProps {}

export const Branding: React.FC<BrandingProps> = () => {
    const windowSize = useWindowSize()

    return (
        <Box data-testid='Branding' position='fixed' zIndex={40} mt='48px'>
            <Link to='/'>
                {windowSize[0] <= 1024 && windowSize[0] >= 768 && (
                    <Box width={128.28}>
                        <img alt='Shard' src={shardMobile} />
                    </Box>
                )}
                {windowSize[0] > 1024 && (
                    <Box width={165}>
                        <img alt='Shard' src={shardDesktop} />
                    </Box>
                )}
                {windowSize[0] < 768 && (
                    <Box width={110}>
                        <img alt='Shard' src={shardMobile} />
                    </Box>
                )}
            </Link>
        </Box>
    )
}
