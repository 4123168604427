import { Box, Collapse, ListItem, ListItemText } from '@material-ui/core'
import { Link } from '@reach/router'
import { useDesktop } from 'common/hooks/useDesktop'
import React, { CSSProperties, useState } from 'react'
import TagManager from 'react-gtm-module'
import { ReactComponent as ChatIcon } from '../Chat.svg'
import { NavigationItem } from '../interfaces'
import { ReactComponent as ArrowDropdown } from './arrowDropdown.svg'
import { ReactComponent as ArrowDropdownSolid } from './arrowDropdownSolid.svg'
import { NavbarMenuList } from './NavbarMenuList'

export interface NavbarMenuListItemProps {
    last?: boolean
    level: number
    page: NavigationItem
}
interface ItemStyling {
    backgroundColor: CSSProperties['backgroundColor']
    color?: CSSProperties['color']
}

interface StylingLevel {
    collapsed: ItemStyling
    expanded?: ItemStyling
}

interface StylingLevels {
    [index: number]: StylingLevel
}

const styles: StylingLevels = {
    1: {
        collapsed: { backgroundColor: '#FFF' },
        expanded: { backgroundColor: '#EBEDF7', color: '#1D2F7C' }
    },
    2: {
        collapsed: { backgroundColor: '#F1F3FD' },
        expanded: { backgroundColor: '#F1F3FD', color: '#4D599E' }
    },
    3: { collapsed: { backgroundColor: '#EBEDF7' } }
}

const arrows = {
    desktop: { collapsed: <ArrowDropdown />, expanded: <ArrowDropdownSolid /> },
    mobile: { collapsed: <ArrowDropdown />, expanded: <ArrowDropdownSolid /> }
}

export const NavbarMenuListItem: React.FC<NavbarMenuListItemProps> = ({
    last,
    level,
    page
}) => {
    const desktop = useDesktop()
    const [expanded, setExpanded] = useState(false)

    const hasSubmenu = !!page.pages
    return (
        <LinkTo url={page.url || ''} subMenu={hasSubmenu}>
            <ListItem
                button
                divider={level === 2 && expanded}
                onClick={() => {
                    if (hasSubmenu) setExpanded(!expanded)
                }}
                selected={false}
                style={{
                    ...styles[level][expanded ? 'expanded' : 'collapsed'],
                    padding: 0
                }}
            >
                <Box
                    borderBottom={
                        desktop && !last ? '1px solid #EBEDF7' : undefined
                    }
                    display='flex'
                    alignItems='center'
                    height={desktop ? 50 : 72}
                    width='100%'
                    px={6}
                >
                    <ListItemText
                        onClick={() =>
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'navigationClick',
                                    clickText: page.name,
                                    clickURL: page.url || '',
                                    clickID: page.name
                                }
                            })
                        }
                        style={{ margin: 0 }}
                        primary={
                            <Box
                                alignItems='center'
                                display='flex'
                                flexDirection='row'
                            >
                                {page.featured && (
                                    <Box mr={2}>
                                        <ChatIcon />
                                    </Box>
                                )}
                                {page.name}
                            </Box>
                        }
                        primaryTypographyProps={{
                            style: {
                                color:
                                    styles[level][
                                        expanded ? 'expanded' : 'collapsed'
                                    ]?.color || '#717FB8',
                                fontWeight: 500,
                                fontSize: 16,
                                marginRight: 36
                            }
                        }}
                    />
                    <Box flex={1} />
                    {hasSubmenu && (
                        <Box style={{ marginBottom: -5, marginRight: -8 }}>
                            {
                                arrows[desktop ? 'desktop' : 'mobile'][
                                    expanded ? 'expanded' : 'collapsed'
                                ]
                            }
                        </Box>
                    )}
                </Box>
            </ListItem>
            {hasSubmenu && (
                <Collapse in={expanded}>
                    <NavbarMenuList level={level + 1} pages={page.pages} />
                </Collapse>
            )}
        </LinkTo>
    )
}
const LinkTo: React.FC<{ url: string; subMenu: boolean }> = ({
    children,
    url,
    subMenu
}) => {
    const regex = /(https:\/\/|http:\/\/)/gi
    const isExternalUrl = regex.test(url || '')
    if (!subMenu) {
        if (isExternalUrl)
            return (
                <a href={url} target='_blank' rel='noopener noreferrer'>
                    {children}
                </a>
            )
        else return <Link to={url}>{children}</Link>
    }
    return <>{children}</>
}
