import { Box } from '@material-ui/core'
import {
    CallToAction,
    CallToActionProps
} from 'common/components/molecules/CallToAction'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

export interface DefaultSectionProps {
    callToAction?: CallToActionProps
    title: string
    text?: string
}
const useStyles = makeStyles({
    h6: {
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 1.32
    }
})

export const DefaultSection: React.FC<DefaultSectionProps> = props => {
    const { callToAction, title, text } = props
    const classes = useStyles()

    const desktop = useDesktop()

    return (
        <Box {...{ mb: [8, 8, 13] }}>
            {/* Title */}

            {typeof title !== 'undefined' && title.includes('@') ? (
                <a href={'mailto:' + title} className={classes.h6}>
                    {title}
                </a>
            ) : typeof title !== 'undefined' && title.includes('+') ? (
                <a href={'tel:' + title} className={classes.h6}>
                    {title}
                </a>
            ) : (
                <span className={classes.h6}>{title}</span>
            )}

            {/* Section Text */}
            {text && (
                <Text
                    style={{ lineHeight: '1', wordWrap: 'break-word' }}
                    html
                    box={{ mb: desktop ? 6 : 0 }}
                    label={text}
                    variant='subtitle2'
                    component='p'
                />
            )}
            {callToAction && callToAction.text && (
                <CallToAction {...callToAction} size='medium' />
            )}
        </Box>
    )
}
