import { Grid } from '@material-ui/core'
// import { Dropdown } from 'common/components/design-system/molecules/selectDropdown'
import { Select, showErrorOnChange } from 'mui-rff'
import React from 'react'

export const timeSlots = [
    {value: 0, label: 'Select a time'},
    {value: 1, label: '8am - 9am'},
    {value: 2, label: '9am - 10am'},
    {value: 3, label: '10am - 11am'},
    {value: 4, label: '11am - 12pm'},
    {value: 5, label: '12pm - 1pm'},
    {value: 6, label: '1pm - 2pm'},
    {value: 7, label: '2pm - 3pm'},
    {value: 8, label: '3pm - 4pm'},
    {value: 9, label: '4pm - 5pm'},
    {value: 10, label: '5pm - 6pm'},
    {value: 11, label: '6pm - 7pm'},
    {value: 12, label: '7pm - 8pm'}
]

export interface ContactLeftFieldsSelectProps {
    error?: string
    setTimeSlot: (number: number) => void
    value: number
}

export const ContactLeftFieldsSelect: React.FC<ContactLeftFieldsSelectProps> = ({
    error,
    setTimeSlot,
    value
}) => {
    return (
        <>
            <Grid container spacing={4}>
                <Select
                    showError={showErrorOnChange}
                    name={'timeSlot'}
                    data={timeSlots}
                    variant={'outlined'}
                />

            </Grid>
            {/* {error && <FormHelperText error>{error}</FormHelperText>} */}
        </>
    )
}
