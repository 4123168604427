import { Box } from '@material-ui/core'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
export interface SpacerProps {
    variant?: 'xs' | 'sm' | 'md' | 'lg'
}
const space = {
    desktop: {
        xs: 12,
        sm: 18,
        md: 24,
        lg: 36
    },
    mobile: {
        xs: 6,
        sm: 8,
        md: 16,
        lg: 24
    }
}
export const Spacer: React.FC<SpacerProps> = ({ variant = 'lg' }) => {
    const desktop = useDesktop()
    return (
        <Box
            data-testid='Spacer'
            pt={space[desktop ? 'desktop' : 'mobile'][variant]}
        />
    )
}
