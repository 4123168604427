import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React, { CSSProperties } from 'react'

export interface FooterSubheadingProps {
    label: string
}

export const FooterSubheading: React.FC<FooterSubheadingProps> = ({
    label
}) => {
    const desktop = useDesktop()
    const textAlign = desktop
        ? undefined
        : ('center' as CSSProperties['textAlign'])
    return (
        <Text
            box={{
                mb: 3,
                style: { textAlign }
            }}
            label={label}
            variant='caption'
            style={{ textTransform: 'uppercase' }}
        />
    )
}
