import { Link } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React, { CSSProperties } from 'react'
import TagManager from 'react-gtm-module'
import { Box } from '@chakra-ui/react'

export interface FooterLinkProps {
    label: string
    url: string
    variant?: 'top' | 'bottom'
    icon?: { url: string; iconSize?: number }
}

export const FooterLink: React.FC<FooterLinkProps> = ({
    label,
    url,
    variant = 'top',
    icon,
}) => {
    const desktop = useDesktop()
    const textAlign = desktop
        ? undefined
        : ('center' as CSSProperties['textAlign'])

    const styles = {
        link2: {
            color: '#fff',
            fontWeight: 600,
            fontSize: 14,
            lineHeight: 20 / 14,
            textAlign,
        },
        link3: {
            color: '#EBEDF7',
            fontWeight: 600,
            fontSize: 12,
            lineHeight: 16 / 12,
            textAlign,
        },
    }

    if (variant === 'top')
        return (
            <Link
                href={url}
                onClick={() =>
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'footerClick',
                            clickText: label,
                            clickURL: url || '',
                            clickID: label,
                        },
                    })
                }
            >
                <Box
                    mb={desktop ? 3 : 7}
                    style={
                        icon && {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: desktop ? '' : 'center',
                        }
                    }
                >
                    {icon && (
                        <img
                            alt={label}
                            src={icon.url}
                            style={{
                                width: icon.iconSize || 24,
                                height: icon.iconSize || 24,
                                marginRight: '16px',
                            }}
                        />
                    )}

                    <Text label={label} style={styles.link2} />
                </Box>
            </Link>
        )
    return (
        <Link
            href={url}
            onClick={() =>
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'footerClick',
                        clickText: label,
                        clickURL: url || '',
                        clickID: label,
                    },
                })
            }
        >
            <Box
                mb={desktop ? 0 : 6}
                mt={desktop ? 0 : 3}
                style={
                    icon && {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: desktop ? '' : 'center',
                    }
                }
            >
                {icon && (
                    <img
                        alt={label}
                        src={icon.url}
                        style={{
                            width: icon.iconSize || 24,
                            height: icon.iconSize || 24,
                            marginRight: '16px',
                        }}
                    />
                )}

                <Text label={label} style={styles.link2} />
            </Box>
        </Link>
    )
}
