import { CardMedia } from '@material-ui/core'
import { CallToActionProps } from 'common/components/molecules/CallToAction'
import React from 'react'
import { FeaturedContentIndented } from './FeaturedContentIndented'

export interface ContactFeatureListProps {
    title: string
    description: string
    icon: string
    callToAction: CallToActionProps
}

export interface ContactRightDetailsProps {
    featureList: ContactFeatureListProps[]
}

export const ContactRightDetails: React.FC<ContactRightDetailsProps> = ({
    featureList = []
}) => {
    return (
        <>
            {featureList.map(feature => (
                <FeaturedContentIndented
                    key={feature.title}
                    icon={
                        <CardMedia
                            image={feature.icon}
                            title={feature.title}
                            style={{ width: 24, height: 24 }}
                        />
                    }
                    title={feature.title}
                    text={feature.description}
                    callToAction={feature.callToAction}
                />
            ))}
        </>
    )
}
