type MenuItem = {
    codename: string
    lastModified: string
    type: string
    menu_item_internal_link_915dd0f__label?: string
    menu_item_internal_link_915dd0f__url?: string
    subitems?: Subitem[]
}

type Subitem = {
    codename: string
    lastModified: string
    type: string
    label: string
    url: string
}

type InputArrayItem = {
    codename: string
    lastModified: string
    type: string
    menu_items: MenuItem[]
}

type OutputStructure = {
    [key: string]: {
        name: string
        url?: string
        pages?: {
            [key: string]: {
                name: string
                url: string
                featured?: boolean
                pages?: any
            }
        }
    }
} | null

export function getCustomNavigationMenuData(
    data: InputArrayItem[]
): OutputStructure {
    if (!data) return null

    const output: OutputStructure = {}

    data.forEach((item) => {
        item.menu_items.forEach((menuItem) => {
            const key = menuItem.codename
            output[key] = {
                name: menuItem.menu_item_internal_link_915dd0f__label || '',
                url: menuItem.menu_item_internal_link_915dd0f__url,
                pages: {},
            }

            menuItem.subitems?.forEach((subitem) => {
                if (!output[key].pages) {
                    output[key].pages = {}
                }
                output[key].pages![subitem.codename] = {
                    name: subitem.label,
                    url: subitem.url,
                }
            })

            // Check if the pages object is empty and delete it if so
            if (Object.keys(output[key].pages!).length === 0) {
                delete output[key].pages
            }
        })
    })

    return output
}
