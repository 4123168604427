import {
    LEAD_GENERATION_TOKEN_URL
} from 'core/config/env'

const tokenUrl = LEAD_GENERATION_TOKEN_URL

const headers = {
    'Content-Type': 'application/json',
}

export const getToken = async () => {
    const result = await fetch(tokenUrl, {
        headers: headers,
        method: 'get'
    })

    return await result.json()
}