import { Box, Spinner, Stack } from '@chakra-ui/react'
import loadable from '@loadable/component'
import { Grid, Hidden } from '@material-ui/core'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { useDesktop } from 'common/hooks/useDesktop'
import { ADVISOR_BOOKING_FLAG } from 'core/config/env'
import React from 'react'
import { AdvisorBookingProps } from '../AdvisorBooking'
import { ContactLeft, ContactLeftProps } from './ContactLeft'
import { ContactConfirmationProps } from './ContactLeft/ContactConfirmation'
import { ContactRight, ContactRightProps } from './ContactRight'

export interface ContactProps extends ContactLeftProps, ContactRightProps {
    tabName: string
    confirmation: ContactConfirmationProps
    advisorBooking: AdvisorBookingProps['customContent']
}

const LoadableComponent = loadable(
    () => import('../AdvisorBooking/AdvisorBooking'),
    {
        fallback: (
            <Wrapper>
                <Stack isInline width='full' justifyContent='center'>
                    <Spinner size='lg' />
                </Stack>
            </Wrapper>
        ),
    }
)

const LoadableAdvisorBooking = (props: any) => {
    return <LoadableComponent {...props} />
}

export const Contact: React.FC<ContactProps> = (props) => {
    const {
        title,
        tabName,
        description,
        featureList,
        advisorBooking,
        ...rest
    } = props

    rest.formValues.tabName = tabName
    const desktop = useDesktop()
    if (!title) return null
    const { hideForm } = rest.formValues
    const showAdvisorBooking = !hideForm && ADVISOR_BOOKING_FLAG
    const showContactForm = hideForm || !ADVISOR_BOOKING_FLAG
    return (
        <Wrapper id='advisor-booking'>
            <LoadableAdvisorBooking
                customContent={{
                    title: advisorBooking?.title,
                    bodyText: advisorBooking?.body,
                    advisorBulletPoints: advisorBooking?.bulletpoints,
                    advisorQuotation: advisorBooking?.advisor_quotation,
                    advisorImage: advisorBooking?.advisor_image,
                    advisorName: advisorBooking?.advisor_name,
                    advisorJobTitle: advisorBooking?.advisor_jobtitle,
                }}
                customCrmData={{
                    campaignId: advisorBooking?.custom_campaign_id,
                    sourceId: advisorBooking?.custom_source_id,
                    subsourceId: advisorBooking?.custom_subsource_id,
                }}
                sx={{ display: showAdvisorBooking ? 'block' : 'none' }}
            />
            <Box
                data-testid='Contact'
                id='contact-form'
                sx={{ display: showContactForm ? 'block' : 'none' }}
            >
                <Grid container spacing={desktop ? 9 : 0}>
                    {!rest.formValues.hideForm && (
                        <Grid item xs={12} md={6}>
                            <ContactLeft {...rest} />
                        </Grid>
                    )}
                    <Hidden smDown>
                        <Grid item md={1} />
                    </Hidden>
                    <Grid item xs={12} md={5}>
                        <ContactRight
                            featureList={featureList}
                            title={title}
                            description={description}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Wrapper>
    )
}
