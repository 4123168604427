import {
    Box,
    Breadcrumbs as MuiBreadcrumbs,
    Link,
    Typography,
} from '@material-ui/core'
import { Link as RouterLink } from '@reach/router'
import { NavigationLink } from 'common/components/organisms/Navbar'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { Spacer } from '../Spacer'
import { BreadcrumbsMobile } from './BreadcrumbsMobile'

export type Breadcrumb = Omit<NavigationLink, 'path'> & Partial<NavigationLink>
export type BreadcrumbsInterface = Breadcrumb[]

export interface BreadcrumbsProps {
    breadcrumbs: BreadcrumbsInterface
    home?: NavigationLink
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
    breadcrumbs = [],
    home = { name: 'Home', url: '/' },
}) => {
    const breadcrumbsWithHome = [home, ...breadcrumbs]
    const desktop = useDesktop()
    if (!desktop) return <BreadcrumbsMobile breadcrumbs={breadcrumbsWithHome} />
    return (
        <>
            <Spacer variant={desktop ? 'lg' : 'sm'} />
            <MuiBreadcrumbs
                style={{ color: '#B0ADBF', fontSize: 12, fontWeight: 600 }}
            >
                {breadcrumbsWithHome.map(({ name, url }, index) => {
                    if (breadcrumbs.length === index)
                        return (
                            <Typography
                                key={name}
                                style={{
                                    color: '#766F90',
                                    fontSize: 12,
                                    fontWeight: 600,
                                }}
                            >
                                {name}
                            </Typography>
                        )
                    return (
                        <Link
                            component={RouterLink}
                            key={url}
                            to={url}
                            style={{ color: '#B0ADBF' }}
                        >
                            {name}
                        </Link>
                    )
                })}
            </MuiBreadcrumbs>
            <Box mt={9} />
        </>
    )
}
